<template>
  <div>
    <validation-provider
      #default="{ errors }"
      :name="label"
      :rules="rules"
      vid="supplier-name"
    >
      <div>
        <label
          :class="`ml-1 ${ rules!=='' ? 'font-weight-bolder ' : ''}` "
          for="account"
        >{{ $t(label) }}</label>
        <div class="d-flex">
          <autosuggest
            id="supplier-name"
            ref="autocomplete"
            v-model="searchQuery"
            class="l-tableList-collector__auto-suggest w-100"
            :suggestions="suggestions"
            :input-props="getSearchInputProps()"
            v-bind="getSuggestProps()"
            :table-suggestion="true"
            :is-editable="isEditable"
            :render-suggestion="renderSuggestion"
            :get-suggestion-value="getSuggestionValue"
            :table-suggestion-configs="autoSuggestTableColumns"
            :is-table-head="false"
            @input="fetchResults"
            @selected="selectHandler"
          >
            <template slot="after-input">
              <b-spinner
                v-if="searchLoading || onCustomerLoadingIfPropsHasCustomerID"
                class="spinner"
                variant="secondary"
                small
              />
            </template>
            <!--        </autosuggest>-->
            <b-button
              class="searchButton ml-1"
              variant="success"
              size="small"
              @click="fetchResults(searchQuery)"
            >
              <feather-icon
                icon="LSearchIcon"
                size="16"
              />
            </b-button>
          </autosuggest>
          <b-button
            class="btn-mini"
            variant="secondary"
            style="margin-left: 8px;min-width: 36px; max-width: 36px; height: 36px"
            @click="searchButton"
          >
            <feather-icon
              icon="LSearchIcon"
              size="16"
            />
          </b-button>
        </div>
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import AutoSuggestSection from '@/views/components/AutoSuggest/parts/AutoSuggestSection.js'
import Autosuggest from '@/views/components/AutoSuggest/Autosuggest.vue'
import { BButton, BSpinner } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import autosuggest from '@/views/components/AutoSuggest/Autosuggest.vue'
import { debounce } from '@core/utils/utils'

export default {
  name: 'SelectAccount',
  components: {
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    AutoSuggestSection,
    Autosuggest,
    BSpinner,
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
    customerID: {
      type: [String, Number],
    },
    autoSuggestTableColumns: {
      type: Array,
      required: true,
    },
    rules: {
      type: String,
      default: '',
      required: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      suggestions: [],
      searchQuery: '',
      autoSuggestLoading: false,
      onCustomerLoadingIfPropsHasCustomerID: false,
    }
  },
  computed: {
    searchLoading() {
      return this.autoSuggestLoading && this.searchQuery
    },
  },
  watch: {
    searchQuery(val) {
      if (val) return

      this.$emit('onSearchEmpty')
    },
  },
  created() {
    if (this.customerID) {
      this.onCustomerLoadingIfPropsHasCustomerID = true
      this.$store.dispatch(`${this.moduleName}/getCustomer`, { id: this.customerID })
        .then(({ data: { data } }) => {
          this.searchQuery = data.cust_name_dba
        })
        .finally(() => {
          this.onCustomerLoadingIfPropsHasCustomerID = false
        })
    }
  },
  mounted() {
    this.$emit('autosuggest', this.$refs.autocomplete)
  },
  methods: {
    searchButton() {
      this.getSelectOptions(this.searchQuery)
      this.$refs.autocomplete.loading = false
    },
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'table-search',
        placeholder: 'Search...',
        class: 'form-control',
        name: 'table-search',
        // style: { borderRadius: this.collectionOfListArr.length ? '0 0 5px 5px !important' : '5px !important' },
      }
      return {
        ...defaultInputProps,
      }
    },
    getSuggestProps() {
      const defaultProps = {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
      return {
        ...defaultProps,
      }
    },
    fetchResults(searchQuery) {
      this.search(searchQuery, this)
      this.$emit('onInput', searchQuery)
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion

      return item.cust_name_dba
    },
    renderSuggestion(suggestion) {
      return suggestion.item.cust_name_dba
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 500),
    getSelectOptions(params) {
      this.suggestions = []
      if (params && params.length > 2) {
        this.autoSuggestLoading = true

        this.$store.dispatch(`${this.moduleName}/getSuppliers`, {
          search: params,
          f: {
            allow_to_purchase_from_supplier: '1',
            is_active: 1,
          },
        }).then(({ data }) => {
          const mappedData = data.data.data.map(item => ({
            ...item,
            name: item.cust_name_dba ? item.cust_name_dba : '---',
          }))
          this.suggestions = [{ data: mappedData }]
        }).finally(() => {
          this.autoSuggestLoading = false
        })
      }
    },
    selectHandler(suggest) {
      if (!suggest) return

      const { item } = suggest

      this.$emit('selectHandler', item)
    },
  },
}
</script>
<style lang="scss">
#supplier-name {
  position: relative;

  & .form-control {

  }

  & .spinner {
    position: absolute;
    top: 12px;
    right: 8px;
  }
}

.autosuggest__results-container {
  & table {
    margin-bottom: 0;
  }
}
</style>
