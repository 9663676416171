<template>
  <div v-if="!loading">
    <error-alert
      :fields="dispatchFields"
      :error="error"
    />
    <div>
      <validation-observer
        ref="dispatchCreateVal"
      >
        <b-row>
          <b-col
            md="6"
          >
            <component
              :is="dispatchFields['name'].type"
              v-model="dispatch['name']"
              v-bind="getProps('name', dispatchFields)"
              :is-editable="isEdit"
            />
          </b-col>
          <div
            class="d-flex flex-column justify-content-between w-50"
          >
            <b class="ml-2">{{ $t('Status') }}</b>
            <b-col
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                id="is_active"
                v-model="dispatch['is_active']"
                :disabled="isEdit"
                name="checkbox-1"
                class="m-0"
                style="height: 30px"
              />
              <label
                for="is_active"
                class="m-0"
                style="height: 30px"
              >{{ $t('Active') }}</label>
            </b-col>
          </div>
        </b-row>
        <b-row class="d-flex flex-column">
          <h1 class="d-block font-weight-bolder font-medium-5 pl-1">
            {{ $t('Rules') }}
          </h1>
          <b-col md="6">
            <span class="d-flex flex-column pl-1">
              <b-form-checkbox
                v-model="dispatch['is_courier']"
                inline
                :disabled="isEdit"
              >
                {{ $t('Is courier') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-model="dispatch['requires_shipping_contact_id']"
                :disabled="isEdit"
                class="mb-1"
                inline
              >
                {{ $t('Requires shipping contact ID') }}
              </b-form-checkbox>
              <select-account
                ref="selectAccount"
                :auto-suggest-table-columns="autoSuggestTableColumns"
                :module-name="MODULE_NAME"
                :is-editable="isEdit"
                label="Linked supplier account"
                :rules="selectAccountRules()"
                @selectHandler="selectHandler"
                @autosuggest="autosuggest"
                @onSearchEmpty="onSearchEmpty"
              />
            </span>
          </b-col>
        </b-row>
        <b-row class="d-flex flex-column mt-1">
          <h1 class="font-weight-bolder font-medium-5 pl-1">
            {{ $t('Payment Terms') }}
            <b-col>
              <div
                v-for="({label, id, rules}) in paymentTermsList"
                :key="id"
              >
                <b-form-checkbox
                  v-if="dispatch.is_courier ? rules === chargeAtOrderApproval: true"
                  :disabled="isEdit"
                  class="d-flex flex-column font-weight-normal"
                  :checked="isChecked(id, 'payment_terms', 'id')"
                  @change="getCheckboxValue($event, id, 'payment_terms')"
                >{{ $t(label) }}
                </b-form-checkbox>
              </div>
            </b-col>
          </h1>
        </b-row>
        <b-row class="d-flex flex-column">
          <h1 class="font-weight-bolder font-medium-5 pl-1 mt-1">
            {{ $t('Integrations') }}
          </h1>
          <b-col md="6">
            <component
              :is="dispatchFields['ecommerce_shipping_method_id'].type"
              v-model="dispatch['ecommerce_shipping_method_id']"
              class="ml-1"
              v-bind="getProps('ecommerce_shipping_method_id', dispatchFields)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            v-if="isFormChanged"
            type="button"
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="loader"
          >
            <feather-icon
              icon="LCancelIcon"
              size="16"
            />
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            v-else
            type="button"
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="cancel"
          >
            {{ $t('Back to List') }}
          </b-button>
        </div>
        <div>
          <b-button
            variant="success"
            type="button"
            class="saveBtn font-medium-1 font-weight-bolder"
            :disabled="isCreateForm ? isLoading : !isFormChanged"
            @click="submit"
          >
            <span v-if="!isLoading">
              {{ $t('SAVE') }}
            </span>
            <b-spinner
              v-if="isLoading"
              small
              label="Small Spinner"
            />
          </b-button>
        </div>
      </div>
    </portal>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
  <div v-else>
    <skeleton />
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import { getProps, scrollToError } from '@core/utils/utils'
import {
  BButton, BRow, BCol, BForm, VBToggle, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import SelectAccount from './SelectAccount.vue'
import config from '../config'
import Skeleton from './Skeleton.vue'

export default {
  name: 'DispatchForm',
  components: {
    SelectAccount,
    BSpinner,
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    BForm,
    ErrorAlert,
    ValidationObserver,
    Skeleton,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      loading: false,
      onSubmit: false,
      isLoading: false,
      textSearchLinkedSupplierAccount: '',
    }
  },
  computed: {
    dispatch() {
      return this.$store.state[this.MODULE_NAME].dispatchForm
    },
    // customerID() {
    //   return this.$store.state[this.MODULE_NAME].dispatchForm.linked_supplier_account_id
    // },
    dispatchClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingsDispatchFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.dispatch) !== this.dispatchClone
    },
    paymentTermsList() {
      return this.$store.state.listModule.paymentTermList
    },
    // paymentOptionList() {
    //   return this.$store.state.listModule.paymentOptionList
    // },
    isUpdate() {
      return this.dispatch.id
    },
  },
  created() {
    this.$store.dispatch('listModule/paymentTermList', { is_active: 1 })
    // this.$store.dispatch('listModule/paymentOptionList', { is_active: 1 })
  },
  methods: {
    autosuggest(e) {
      e.$data.internalValue = this.dispatch.linked_supplier_account?.cust_name_dba
      this.$refs.selectAccount.searchQuery = this.dispatch.linked_supplier_account?.cust_name_dba
    },
    selectAccountRules() {
      return this.dispatch.is_courier ? 'required' : ''
    },
    searchLinkedSupplierAccount(e) {
      this.textSearchLinkedSupplierAccount = e
    },
    searchLinkedSupplierAccountHandle() {
      console.log(this.textSearchLinkedSupplierAccount)
    },
    getCheckboxValue(e, id, listName) {
      if (e) {
        this.dispatch[listName].push({
          id,
          is_checked: `${e}`,
        })
      } else {
        this.dispatch[listName] = this.dispatch[listName].filter(item => item.id !== id)
      }
    },
    isChecked(id, listName, itemKeyName) {
      return Array.isArray(this.dispatch[listName]) && this.dispatch[listName].find(item => item[itemKeyName] === id)?.is_checked
    },
    selectHandler(item) {
      this.dispatch.linked_supplier_account_id = item.id
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.dispatchCreateVal.validate()
        .then(success => {
          if (success) {
            this.isLoading = true
            const data = this.dispatch
            const dispatchId = this.dispatch.id
            const defaultDispatch = this.mappingFields(Object.keys(this.dispatchFields), data, {
              ...data,
              id: dispatchId,
              name: data.name,
              is_active: data.is_active ? 'true' : 'false',
              is_courier: data.is_courier ? 'true' : 'false',
              requires_shipping_contact_id: data.requires_shipping_contact_id ? 'true' : 'false',
              ecommerce_shipping_method_id: data.ecommerce_shipping_method_id?.id,
              linked_supplier_account_id: this.dispatch.linked_supplier_account_id,
            })
            this.sendNotification(this, { ...defaultDispatch, dispatchId }, `${this.MODULE_NAME}/${this.dispatch.id ? 'update' : 'create'}`)
              .then(res => {
                this.clear()
                // this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id)
                this.$router.replace({
                  name: 'settings-fulfillment-dispatch-list',
                  params: { id: res.data.data?.id },
                })
              })
              .catch(err => {
                this.error = err.response?.data
                this.isLoading = false
              }).finally(() => {
                this.onSubmit = false
                this.isLoading = false
              })
          } else {
            scrollToError(this, this.$refs.dispatchCreateVal)
          }
        })
    },
    clear() {
      this.$refs.dispatchCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    },
    cancel() {
      this.$router.push({ name: 'settings-fulfillment-dispatch-list' })
    },
    loader() {
      this.$refs.dispatchCreateVal.reset()
      const smth = JSON.parse(this.dispatchClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    onSearchEmpty() {

    },
  },

  setup() {
    const {
      MODULE_NAME, MODULE_NAME_CLONE, dispatchFields, autoSuggestTableColumns, chargeAtOrderApproval,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      dispatchFields,
      autoSuggestTableColumns,
      chargeAtOrderApproval,
    }
  },
}
</script>
