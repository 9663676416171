<template>
  <div>
    <b-row class="mt-2">
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
      <b-col md="6">
        <b-skeleton type="checkbox" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow, BSkeleton } from 'bootstrap-vue'

export default {
  name: 'DispatchSkeleton',
  components: { BCol, BSkeleton, BRow },

}
</script>
